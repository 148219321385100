<template>
  <div class="LogOut" />
</template>

<script>
export default {
  name: 'LogOut',
}
</script>

<style lang="sass" scoped>
.LogOut
  background-color: yellow
  height: 100vh
  width: 100%
</style>
